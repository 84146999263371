<script setup lang="ts">

</script>

<template>
  <div class="footer-top">
    <NuxtImg
      width="160"
      height="100"
      loading="lazy"
      densities="x1, x2"
      src="logo.png"
      format="webp"
      alt="logo Planète-Croisière.com"
      class="footer-logo"
    />
    <div class="footer-social-container">
      <NuxtLink
        class="footer-social__link"
        to="https://www.facebook.com/planetecroisiere"
        target="_blank"
        title="Facebook"
      >
        <UiIconsSocialFacebook />
      </NuxtLink>
      <NuxtLink
        class="footer-social-link"
        to="https://www.youtube.com/channel/UCdmdf26aF9F-aHJoy3vB2Yg"
        target="_blank"
        title="YouTube"
      >
        <UiIconsSocialYouTube />
      </NuxtLink>
      <NuxtLink
        class="footer-social-link"
        to="https://www.linkedin.com/company/planete-croisiere/"
        target="_blank"
        title="Linkedin"
      >
        <UiIconsSocialLinkedIn />
      </NuxtLink>
      <NuxtLink
        class="footer-social-link"
        to="https://www.instagram.com/planetecroisiere/"
        target="_blank"
        title="Instagram"
      >
        <UiIconsSocialInstagram />
      </NuxtLink>
    </div>
    <div class="footer-information-container">
      <div class="footer-information-container-item">
        <span class="number">
          36
        </span>
        <span class="text">
          {{ $t('footer.middle.information.cruiseline') }}
        </span>
      </div>
      <div class="footer-information-container-item">
        <span class="number">
          10 501
        </span>
        <span class="text">
          {{ $t('footer.middle.information.cruises') }}
        </span>
      </div>
      <div class="footer-information-container-item">
        <span class="number">
          33 187
        </span>
        <span class="text">
          {{ $t('footer.middle.information.stopovers') }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.footer-top {
  display: flex;
  gap: 0;
  width: 100%;
  flex-wrap: wrap;
  border-top: 1px solid var(--color-navy-300);
  padding-top: $space-md;

  @media (min-width: map-get($grid-breakpoints, md)) {
    border: none;
    gap: 1em;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    gap: 100px;
    flex-wrap: nowrap;
    align-items: center;
  }

  .footer-logo {
    height: 100px;
    width: auto;
  }

  .footer-social-container {
    display: flex;
    gap: 0.5em;
    align-items: center;
    margin-left: auto;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin-left: unset;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      gap: 1.5em;
    }

    img {
      width: 24px;
    }

    .footer-social__link, .footer-social-link {
      svg {
        width: 2.2em;
        height: 2.2em;
      }
    }
  }

  .footer-information-container {
    display: flex;
    flex-direction: column;
    gap: $space-xs;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, md)) {
      flex-direction: row;
      justify-content: center;
      gap: 4rem;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin-left: auto;
      align-items: center;
      justify-content: unset;
      width: auto;
    }

    .footer-information-container-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (min-width: map-get($grid-breakpoints, md)) {
        flex-direction: column;
        justify-content: flex-start;
      }

      .number {
        font-size: 2.5rem;
        font-weight: bold;
        color: var(--color-navy-450);
        font-family: var(--font-secondary);
      }

      .text {
        font-size: $space-sm;
        color: var(--color-navy-450);
        order: -1;

        @media (min-width: map-get($grid-breakpoints, md)) {
          order: unset;
        }
      }
    }
  }
}
</style>
