<script setup lang="ts">

</script>

<template>
  <div class="footer-bottom">
    <div class="footer-bottom-information">
      <div class="footer-bottom-information-address">
        <p>
          {{ $t('footer.bottom.cruiseline.website') }}
        </p>
        <p>
          {{ $t('footer.bottom.cruiseline.address') }}
        </p>
        <p>
          <a
            class="phone"
            :title="$t('phone')"
            :href="'tel:' + $t('phone_link')"
          >{{ $t('phone') }}</a>
        </p>
      </div>
      <div class="footer-bottom-information-payment">
        <p>
          <img
            loading="lazy"
            width="219"
            height="37"
            src="/images/icons/secure.svg"
            alt="Payment secure"
          >
          {{ $t('footer.bottom.payment') }}
        </p>
        <NuxtImg
          width="219"
          height="37"
          loading="lazy"
          src="/images/footer/payment-list.jpg"
          format="webp"
          alt="Payment"
        /><br>
        <span class="captcha-message">
          Ce site est protégé par reCAPTCHA.<br>Les
          <a
            target="_blank"
            href="https://policies.google.com/privacy"
            title="Google privacy"
          >règles de confidentialité</a> et
          <a
            target="_blank"
            href="https://policies.google.com/terms"
            title="Google terms"
          >conditions d'utilisation</a> de Google s'appliquent.
        </span>
      </div>
    </div>
    <div class="footer-bottom-link">
      <ul>
        <li
          v-for="(item, index) in $tm('footer.bottom.links')"
          :key="index"
        >
          <NuxtLinkLocale :to="item.link">
            {{ item.label }}
          </NuxtLinkLocale>
        </li>
      </ul>
    </div>
    <div class="footer-bottom-partners">
      <div class="partner">
        <NuxtImg
          loading="lazy"
          width="69"
          height="40"
          src="/images/footer/partner/apst-logo.png"
          format="webp"
          alt="Apst"
        />
        <p>
          {{ $t('footer.bottom.partners.apst') }}
        </p>
      </div>
      <div class="partner">
        <NuxtImg
          loading="lazy"
          width="69"
          height="40"
          src="/images/footer/partner/atout-france-logo.png"
          format="webp"
          alt="Atout France"
        />
        <p>
          {{ $t('footer.bottom.partners.atoutFrance') }}
        </p>
      </div>
      <div class="partner">
        <NuxtImg
          loading="lazy"
          width="82"
          height="40"
          src="/images/footer/partner/hiscox-logo.png"
          format="webp"
          alt="Hiscox"
        />
        <p>
          {{ $t('footer.bottom.partners.hiscox') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer-bottom {
  margin-top: $space-md;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: map-get($grid-breakpoints, md)) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    flex-wrap: nowrap;
  }

  > div {
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, md)) {
      width: auto;
    }
  }

  .footer-bottom-partners {
    display: flex;
    gap: $space-md;
    flex-wrap: wrap;
    padding-top: $space-md;

    @media (min-width: map-get($grid-breakpoints, md)) {
      justify-content: space-between;
      padding-top: 0;

      > div {
        width: 30%;
      }
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      flex-wrap: nowrap;
      padding-left: 5rem;
    }

    .partner {
      display: flex;
      align-items: center;
      gap: $space-sm;

      @media (min-width: map-get($grid-breakpoints, md)) {
        flex-direction: column;
        align-items: center;
      }

      @media (min-width: map-get($grid-breakpoints, lg)) {
        align-items: start;
      }

      img {
        height: 40px;
        max-width: 100%;
      }

      p {
        font-size: 1rem;
        margin-bottom: 0;

        @media (min-width: map-get($grid-breakpoints, md)) {
          line-height: 1.5rem;
          text-align: center;
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
          max-width: 150px;
          text-align: unset;
        }
      }
    }
  }

  .footer-bottom-information {
    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 48%;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      width: auto;
    }

    .footer-bottom-information-address {
      @media (min-width: map-get($grid-breakpoints, md)) {
        width: 300px;
      }

      p {
        margin: 0;
        font-size: 0.8rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
          font-size: 1.13rem;
          line-height: 1.8rem;
        }
      }
    }

    .footer-bottom-information-payment {
      margin-top: $space-md;

      @media (min-width: map-get($grid-breakpoints, md)) {
        margin-top: $space-lg;
      }

      > img {
        height: 40px;

        @media (min-width: map-get($grid-breakpoints, md)) {
          height: auto;
        }
      }

      p {
        display: flex;
        align-items: center;
        gap: $space-xs;
        margin: 0 0 $space-xs;
        font-size: 0.8rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
          font-size: 1rem;
        }

        img {
          width: 20px;
        }
      }

      .captcha-message {
        max-width : 350px;
        font-size: .8em;
        display: block;
      }
    }
  }

  .footer-bottom-link {
    order: -1;
    padding: 0;
    border-color: var(--color-navy-300);
    border-style: solid;
    border-width: 1px 0 1px 0;
    margin-bottom: $space-lg;

    @media (min-width: map-get($grid-breakpoints, md)) {
      order: inherit;
      border: none;
      width: 48%;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      padding-left: 4rem;
    }

    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      list-style: none;
      padding: 0;
      column-gap: 4rem;
      margin: $space-lg 0 $space-sm 0;

      @media (min-width: map-get($grid-breakpoints, md)) {
        margin-bottom: 0;
      }

      li {
        margin-bottom: $space-sm;
        font-size: 1.2rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
          font-size: 1.25rem;
        }

        a {
          line-height: $space-md;
          text-underline-offset: 6px;
        }
      }
    }
  }
}
</style>
